.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.norican-regular {
  font-family: "Norican", cursive;
  font-style: normal;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-style: normal;
}
.full-width {
  width: 100vw !important;
  transition: width 0.5s ease; /* Smooth transition */
}
.full-width-reverse {
  
  transition: width 0.5s ease; /* Smooth transition */
}

body{
  
}

.nav-links:hover {
  border-bottom: 1px solid black;
}

.zoomed-in:hover {
  transform: scale(1.1); 
  transition: transform 0.5s ease; /* Adjust the duration and timing function as needed */
}
#HomeServiceCard:hover{
  border-radius: 100px 30px 100px 30px !important;
}
#HomeServiceCard:hover #HomeServiceCardInner{
  border: 4px solid #313131;
  border-radius: 100px 30px 100px 30px !important;
  background-color: rgba(209, 207, 207, 0.219);
}



.card {
 
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgba(255,255,255,.05);
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  backdrop-filter: blur(0px);
  border-radius: 8px;
}

.card:hover {
  transform: scale(1.04)  ;
}



@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}




@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


.courgette-regular {
  font-family: "Courgette", cursive;
  font-weight: 400;
  font-style: normal;
}


.rubik-font {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}




.splash-regular {
  font-family: "Splash", cursive;
  font-weight: 400;
  font-style: normal;
}



.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}


.slider-container {
  position: relative;
}

.text-overlay {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.animated-text {
  display: inline-block;
  white-space: nowrap;
  animation: slideIn 1s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
